import React, { useEffect, useState } from "react";
import {
  FaSearch,
  FaShoePrints,
  FaSync,
  FaPenSquare,
  FaAddressCard,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  ButtonToolbar,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { api } from "../Api";

export default function Subscriptions() {
  function renderTable(subscriptions) {
    return (
      <table className="table table-striped" aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Name</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((item) => (
            <tr key={item.subscription.id}>
              <td>
                <Link to={`/subscription/${item.subscription.id}/tenant`}>
                  {item.subscription.customerName}
                </Link>
              </td>
              <td>
                <Button onClick={() => showModal(item.subscription)}>
                  <FaPenSquare />
                </Button>
              </td>
            </tr>
          ))}
          {subscriptions.length === 0 && (
            <tr key="noresult">
              <td>
                <p className="text-muted text-center">
                  <FaShoePrints /> No subscriptions
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState();

  const [searchInputSubscription, setSearchString] = useState("");
  const [searchInputTenantName, setTenantNameSearchString] = useState("");

  useEffect(() => {
    setIsLoaded(false);
    setError(null);

    const abortController = new AbortController();
    api.get("getroles").then(function (response) {
      console.log(response.data);
    });
    api
      .get("subscription", {
        signal: abortController.signal,
        params: { includeTenants: true },
      })
      .then(function (response) {
        setSubscriptions(response.data);
        setIsLoaded(true);
      })
      .catch(function (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          setError(error);
        }
      });

    return () => {
      abortController.abort();
    };
  }, [refresh]);

  const showModal = (subscription) => {
    setSelectedSubscription(subscription);
    setModalIsOpen(true);
  };

  const cancelModal = () => {
    setModalIsOpen(false);
  };

  const save = (subscription) => {
    setModalIsOpen(false);
    api
      .put(`subscription/${subscription.id}`, subscription)
      .then((response) => {
        setRefresh(refresh + 1);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  if (error) {
    return <Alert color="danger">Error: {error.message}</Alert>;
  } else {
    return (
      <div>
        <EditSubscriptionModal
          isOpen={modalIsOpen}
          cancel={cancelModal}
          save={save}
          subscription={selectedSubscription}
        />
        <h1 className="mb-4">Subscriptions</h1>

        <ButtonToolbar className="justify-content-between">
          <div>
            <Button className="me-1" tag={Link} to="new">
              New
            </Button>
            <Button
              className="me-1"
              onClick={() => {
                setRefresh(refresh + 1);
              }}
            >
              <FaSync />
            </Button>
          </div>
          {isLoaded && (
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input
                type="text"
                name="search-subscription"
                placeholder="Search by subscription name.."
                value={searchInputSubscription}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </InputGroup>
          )}
        </ButtonToolbar>
        <ButtonToolbar className="justify-content-between">
          <div></div>
          {isLoaded && (
            <InputGroup>
              <InputGroupText>
                <FaAddressCard />
              </InputGroupText>
              <Input
                type="text"
                name="search-tenant-name"
                placeholder="Search by tenant name.."
                value={searchInputTenantName}
                onChange={(e) => setTenantNameSearchString(e.target.value)}
              />
            </InputGroup>
          )}
        </ButtonToolbar>

        {isLoaded ? (
          renderTable(
            subscriptions.filter((item) => {
              const matchesCustomerName =
                !searchInputSubscription ||
                item.subscription.customerName
                  .toLowerCase()
                  .includes(searchInputSubscription.toLowerCase());
              const matchesTenantName =
                !searchInputTenantName ||
                item.tenants.some((tenant) =>
                  tenant.tenantName
                    .toLowerCase()
                    .includes(searchInputTenantName.toLowerCase())
                );
              return matchesCustomerName && matchesTenantName;
            })
          )
        ) : (
          <center>
            <Spinner type="grow">Loading</Spinner>
          </center>
        )}
      </div>
    );
  }
}

class EditSubscriptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} className={this.props.className}>
          <ModalHeader>Change customer name: </ModalHeader>
          <ModalBody>
            <input
              defaultValue={this.props.subscription?.customerName}
              ref={this.myRef}
            ></input>
          </ModalBody>
          <ModalFooter>
            <Button
              className="mx-1"
              color="info"
              onClick={() =>
                this.props.save({
                  ...this.props.subscription,
                  customerName: this.myRef.current?.value,
                })
              }
            >
              Save
            </Button>
            <Button color="secondary" onClick={this.props.cancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
