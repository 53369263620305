import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../Api";

export default function AppConfig() {
  const [featureFlags, setFeatureFlags] = useState();

  const sortByLabel = (a, b) => {
    return a.label.localeCompare(b.label);
  };

  let params = useParams();

  if (!featureFlags) {
    api.get(`featureflags/${params.tenantId}`).then((response) => {
      setFeatureFlags(response.data.sort(sortByLabel));
    });
  }

  const onSubmitFeatureFlags = (event) => {
    event.preventDefault();
    const form = event.target;

    const checkboxElements = Array.from(form.elements).filter(
      (e) => e.type === "checkbox" && !e.disabled
    );

    const flags = checkboxElements.map((element) => {
      return {
        Name: element.id.split("/")[0],
        Enabled: element.checked,
        Label: element.id.split("/")[1],
      };
    });
    api
      .put(`featureflags/${params.tenantId}`, flags)
      .then((response) => {
        console.log(response);
      });
  };

  if (!featureFlags) {
    return <b>Loading...</b>;
  } else {
    console.log(featureFlags);
    return (
      <form
        style={{
          border: "1px solid #dedede",
          margin: "20px auto",
          maxWidth: "100%",
          padding: "30px 30px",
          justifyContent: "space-around",
        }}
        id="featureflags"
        onSubmit={onSubmitFeatureFlags}
      >
        <h3>Feature flags</h3>
        {featureFlags?.map((feature) => (
          <>
            <label
              style={{ alignItems: "center", width: "50%" }}
              data-tip={
                feature.HasTenantFilter ? "Feature is globally enabled" : ""
              }
            >
              <input
                type="checkbox"
                id={feature.name + "/" + feature.label}
                name={feature.name}
                defaultChecked={feature.enabled}
                style={{ marginRight: "5px" }}
                disabled={feature.hasTenantFilter === true ? false : true}
              />
              {feature.name}
            </label>
            <span>{feature.label}</span>
          </>
        ))}
        <input
          type="submit"
          className="submitButton"
          style={{ width: "100%" }}
        />
      </form>
    );
  }
}
